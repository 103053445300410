import logo from './images/twisklogo.png';
import './App.css';
import CountDown from './CountDown';
import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';


// Function to get the time difference in seconds
function getTimeSinceLastVisit() {
    // Retrieve the stored visit time
    const visitTime = localStorage.getItem('visitTime');
  
    if (!visitTime) {
      console.log("No visit time found in localStorage.");
      return null;
    }
  
    // Parse the stored date and get the current date
    const lastVisitDate = new Date(visitTime);
    const currentDate = new Date();
  
    // Calculate the difference in milliseconds and convert to seconds
    const timeDifferenceInSeconds = Math.floor((currentDate - lastVisitDate) / 1000);
  
    return timeDifferenceInSeconds;
  }

export const ChartComponent = (props) => {
    const {
        data,
        colors: {
            lineColor = '#2962FF',
            textColor = 'white', // Default text color set to white
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
        backgroundImage,
    } = props;

    const chartContainerRef = useRef();

    useEffect(() => {
        const handleResize = () => {
            chart.applyOptions({
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
            });
        };

        const chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: 'transparent' },
                textColor,
            },
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            leftPriceScale: {
                borderColor: 'rgba(255, 255, 255, 0.3)', // Light border
                textColor: 'white', // Y-axis label text in white
            },
            timeScale: {
                borderColor: 'rgba(255, 255, 255, 0.3)', // Light border for time axis
            },
        });

        chart.timeScale().fitContent();

        const newSeries = chart.addAreaSeries({
            lineColor,
            topColor: areaTopColor,
            bottomColor: areaBottomColor,
        });
        newSeries.setData(data);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [data, lineColor, textColor, areaTopColor, areaBottomColor]);

    return (
        <div
            ref={chartContainerRef}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
            }}
        />
    );
};

const initialData = [
    { time: '2018-12-22', value: 22.67 },
    { time: '2018-12-23', value: 22.68 },
    { time: '2018-12-24', value: 23.92 },
    { time: '2018-12-25', value: 25.46 },
    { time: '2018-12-26', value: 28.89 },
    { time: '2018-12-27', value: 25.17 },
    { time: '2018-12-28', value: 27.32 },
    { time: '2018-12-29', value: 27.02 },
    { time: '2018-12-30', value: 31.11 },
    { time: '2018-12-31', value: 32.51 },
];

function App() {
    const chartColors = {
        lineColor: '#4caf50', // Updated line color to green
        textColor: '#000', // Updated text color to black
        areaTopColor: '#4caf50', // Updated area top color to green
        areaBottomColor: 'rgba(76, 175, 80, 0.5)', 
    };

    const backgroundImage = './images/chart-background.jpg';

    return (
        <div className="App">

            <ChartComponent
                colors={chartColors}
                data={initialData}
                backgroundImage={backgroundImage}
            />
            <header className="App-header">
                <CountDown seconds={79387460-getTimeSinceLastVisit()}/>
                <a href="http://twisk.co.za">
                    <img src={logo} className="App-logo" alt="logo" />
                </a>
            </header>
        </div>
    );
}

export default App;